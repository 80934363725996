function setRem () {
  const clientWidth = document.documentElement.clientWidth
  const baseSize = 28
  let scale
  if (clientWidth > 900) {
    scale = clientWidth / 1000
  }
  if (clientWidth === 1040) {
    scale = clientWidth / 1040
  }
  document.documentElement.style.fontSize = (baseSize * Math.min(scale, 1)) + 'px'
}
// 初始化
setRem()

window.onresize = function () {
  setRem()
}
