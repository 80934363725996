import axios from 'axios'
import qs from 'qs'
import { Notify } from 'vant'

/* 创建一个新的 axios 对象，确保原有的对象不变 */

const BASE_URL = localStorage.getItem('endpoint')
const axiosWrap = axios.create({
  baseURL: BASE_URL,
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: 'brackets' })
  },
  timeout: 60000
})

/* 过滤响应 */
axiosWrap.interceptors.response.use(null, error => {
  // 统一错误处理
  if (error.response) {
    const { errors, error_messages } = error.response.data
    if (errors && errors.length) {
      Notify({
        type: 'danger',
        message: error_messages.join('，')
      })
    } else if (error.response.status === 503) {
      Notify({
        type: 'danger',
        message: '服务器忙，请稍后重试'
      })
    } else {
      Notify({
        type: 'danger',
        message: `异常报错，错误状态码：${error.response.status}`
      })
    }
  } else if (error.request) {
    Notify({
      type: 'danger',
      message: '请求超时，未获得服务器返回结果'
    })
  } else {
    Notify({
      type: 'danger',
      message: '请求失败，请检查网络连接后重试'
    })
  }

  return Promise.reject(error.response || 'error')
})

export default axiosWrap
