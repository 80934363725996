import openRequest from './open-request.axios.js'

const PAGE_SIZE = 20
export default {
  getBirthday (params) {
    return openRequest.get('/embedded/student', {
      params: {
        student_id: params.student_id
      }
    })
  },
  getBirthdayList (params) {
    return openRequest.get('/embedded/birthdays', {
      params: {
        school_id: params.school_id,
        student_id: params.student_id
      }
    })
  },
  getWishList (params) {
    return openRequest.get('/embedded/birthdays/wish_list', {
      params: {
        school_id: params.school_id,
        student_id: params.student_id
      }
    })
  },
  createBirthdayBless (params) {
    return openRequest.post('/embedded/birthdays', {
      target_student_id: params.target_student_id,
      student_id: params.student_id
    })
  },
  updateStudentBirthday (params) {
    return openRequest.patch('/embedded/student', {
      student_id: params.student_id,
      birthday: params.birthday
    })
  },
  // 表彰
  getPraiseMain (params) {
    return openRequest.get('/embedded/praises/promoted', {
      params: {
        sort: '-created_at',
        school_id: params.school_id
      }
    })
  },
  getGradesLite (params) {
    return openRequest.get('/embedded/grades/lite', {
      params: params
    })
  },
  getHomeroomsLite (params) {
    return openRequest.get('/embedded/homerooms/lite', {
      params: params
    })
  },
  getPraisesList (params) {
    return openRequest.get('/embedded/praises', {
      params: {
        sort: '-created_at',
        school_id: params.school_id,
        'page[number]': params.page || 1,
        'page[size]': params.size || PAGE_SIZE,
        'filter[homeroom_id]': params.homeroom_id || undefined
      }
    })
  }
}
