<template>
  <div id="app">
     <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="less">
@import '~normalize.css';

/* 禁止学生选中文案, 跳转至外层去下载app */
* {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
}
</style>
