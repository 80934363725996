import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes.js'
import { Notify } from 'vant'
import { setToken } from '../utils/user'
import api from '../api/index.js'

Vue.use(VueRouter)

const BASE_URL = process.env.BASE_URL

const router = new VueRouter({
  mode: 'history',
  base: BASE_URL,
  routes
})

// 需要鉴权的路径, 一般为投放首页
const guardPathNames = [
  // 'birthday_bless'
]

router.beforeEach((to, from, next) => {
  const { ident, system } = to.query

  if (system) {
    let domain
    if (process.env.NODE_ENV !== 'production') {
      domain = 'staging.adachina.net'
    } else {
      domain = 'adacampus.com'
    }
    localStorage.setItem('endpoint', `https://${system}-backend.${domain}`)
  }

  if (guardPathNames.indexOf(to.name) > -1) {
    if (!ident) {
      Notify({
        type: 'danger',
        message: '未带中心身份标志，无法使用，请联系管理员到后台配置\n（点击可关闭提示）',
        duration: 4500,
        onClick: () => {
          this.$notify.clear()
        }
      })
    } else {
      api.deviceSSO(ident).then(res => {
        setToken(res.data.token)
        next()
      }).catch(() => {
        Notify({
          type: 'danger',
          message: '登录失败，请重试\n（点击可关闭提示）',
          duration: 4500,
          onClick: () => {
            this.$notify.clear()
          }
        })
      })
    }
  } else {
    next()
  }
  // 百度统计代码
  window._hmt.push(['_trackPageview', '/#' + to.path])
})

router.afterEach((to, from) => {
  if (!to.query._wv || parseInt(to.query._wv) !== 1) {
    to.query._wv = 1
  }
})

export default router
