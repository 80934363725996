import Vue from 'vue'
import router from './routes'
import store from './store'
import App from './App.vue'

import './utils/rem.js'
import './filters'

import {
  Notify,
  Swipe,
  SwipeItem,
  DatetimePicker,
  Toast,
  TreeSelect
} from 'vant'

Vue.use(Notify)
Vue.use(Toast)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(DatetimePicker)
Vue.use(TreeSelect)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
