// 生日祝福
const birthdayRoutes = [
  {
    path: '',
    redirect: {
      name: 'birthday_card'
    }
  },
  {
    path: '/birthday/card',
    name: 'birthday_card',
    component: () => import('../views/birthday/card.vue')
  },
  {
    path: '/birthday/bless',
    name: 'birthday_bless',
    component: () => import('../views/birthday/bless.vue')
  },
  {
    path: '/birthday/list',
    name: 'birthday_list',
    component: () => import('../views/birthday/list.vue')
  }
]

// 表彰
const praiseRoutes = [{
  path: '/praise/main',
  name: 'praise_main',
  component: () => import('../views/praise/main.vue')
},
{
  path: '/praise/detail',
  name: 'praise_detail',
  component: () => import('../views/praise/detail.vue')
}]

export default [
  ...birthdayRoutes,
  ...praiseRoutes
]
