import Cookies from 'js-cookie'

export const setToken = (token) => {
  token = token || ''
  Cookies.set('token', token, { expires: 30 })
}

export const getToken = () => {
  const token = Cookies.get('token')
  if (token) return token
  else return false
}

export const setUser = (user) => {
  user = user || {}
  Cookies.set('user', user, { expires: 30 })
}

export const getUser = () => {
  const user = Cookies.getJSON('user')
  if (user) return user
  else return false
}

export const clearCookies = () => {
  Cookies.remove('user')
  Cookies.remove('token')
}
